/*eslint-disable */
import {bus} from '@/main';
import FlipCountdown from 'vue2-flip-countdown'
export default {
  name: 'blurb-for-conference-popup',
  components: {
    FlipCountdown
  },
  props: [],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    blurbTextPopupClosed(){
      bus.$emit('blurTextbPopupStateBus', false);
      document.body.classList.remove('popup_open_body')
    }
  }
}