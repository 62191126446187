/*eslint-disable */
import {
  mapGetters
} from 'vuex';
import moment from 'moment';
import FlipCountdown from 'vue2-flip-countdown'
var $ = window.jQuery = require('jquery');
import{ bus } from '@/main';
import blurbForConference from '@/components/authentication/blurb-for-conference-popup';
export default {
  name: 'lbil_launch_webinar',
  components: {
    FlipCountdown,
    'blurb-for-conference' : blurbForConference
  },
  props: [],
  data () {
    return {
      userInfoStoreState:false,
      timeElapsedState:true,
      presentDate: new Date(),
      getPublicVistaMeetingData:null,
      blurTextbPopupState:false
    }
  },
  computed: {
    ...mapGetters([
      'getPublicVistaMeetingGetters',
    ]),
    getPublicVistaMeetingMethod(){
      this.getPublicVistaMeetingData = this.$store.getters.getPublicVistaMeetingGetters.data
      return
    },
  },
  mounted () {
    bus.$on('userInfoStoreBus', (data) => {
      this.userInfoStoreState = data;
    });
    bus.$on('blurTextbPopupStateBus', (data) => {
      this.blurTextbPopupState = data;
    });
    this.checkDateAndTimeMethod()
  },
  methods: {
    blurbTextPopupOpen(){
      this.blurTextbPopupState = true
      document.body.classList.add('popup_open_body')
    },
    checkDateAndTimeMethod(){
      if(moment().format('MM-DD-YYYY') >= '10-31-2021' && moment().format('HH:mm:ss') >= '11:00:00'){
        this.timeElapsedState = false
      }else{
        this.timeElapsedState = true
      }
    },
    timeElapsedHandler () {
      this.timeElapsedState = false
    },
    discoverNowMethod(){
      this.userInfoStoreState = true
      $('body').addClass('body_right_scroll_remove')
    }
  },
  watch:{
    getPublicVistaMeetingMethod(){
      return this.$store.getters.getPublicVistaMeetingGetters
    },
  },
  beforeCreate: function() {
    $('body').addClass('lbil_launched_conference_wrp')
  },
  beforeRouteLeave: function(to, from, next) {
    $('body').removeClass('lbil_launched_conference_wrp')
    next();
  },
}